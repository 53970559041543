import React, { useState, useEffect, useRef } from "react"
import { useRouter } from "next/router"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "next-i18next"
import { NumericFormat } from "react-number-format"
import { isTablet } from "react-device-detect"
import cx from "classnames"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import debounce from "lodash/debounce"

import Checkbox from "@/components/core/Checkbox/Checkbox"
import Highlight from "@/components/core/Highlight/Highlight"
import Tooltip from "@/components/core/ToolTip/ToolTip"
import { getConfig } from "@/constants/config"
import ProductSocialShare from "@/components/Shared/ProductSocialShare/v1/ProductSocialShare"
import {
  getUserPersona,
  showNewBadge,
  sanitizeTextForAnalytics,
  getCurrentSkuCouponDiscounts,
  isColorFacet,
  getShortenedUrl,
  getPDPUrlForProductTile,
  sanitizeUrl,
} from "@/utils/helper"

import useWindowResize from "@/hooks/useWindowResize"
import { getPresetUrl, onImageError, handleViewSterlingResults } from "@/utils/"
import { SWATCH_NA } from "@/constants/index"
import { showToast, selectGenericState } from "@/store/features/genericSlice"
import { addProduct, removeItems } from "@/store/features/favoritesSlice"
import {
  selectAuthState,
  setAuthModalVisibility,
} from "@/store/features/authSlice"
import { getTranslations } from "@/components/ProductList/v2/ProductTile/localization"

import swatchMissing from "@/public/images/swatch-missing.jpeg"
import shareIcon from "@/public/icons/share.svg"
import wishIcon from "@/public/icons/wish.svg"
import filledWishIcon from "@/public/icons/wishlist-gray-filled.svg"

const ProductTile = props => {
  const { t } = useTranslation("common")
  const staticText = getTranslations(t)
  const {
    data,
    texts,
    lite,
    loading,
    addToCompare,
    removeFromCompare,
    compareData,
    highlightText,
    hideCompare,
    currencySign,
    isGrouped,
    rowCols,
    presetConfigs = "{}",
    isPartsDiagramLinkVisible = false,
    swatchCount,
    plpPage,
    defaultImage,
    hoverImage,
    mapImageToColor,
    currFilters,
    favorites,
    exactSKU,
    type = "",
    selectedtab,
    handleOnClickPartsDiagramLink,
    favPage,
    index,
    selectedSort,
    onToggle,
    isSearchPanelTile,
    isSterling = false,
    sterlingData = [],
    showSterlingButton = false,
    parent = "",
    brandSwitcherItems = [],
    plpBadge = {},
    searchPagePath = "",
  } = props
  const { plpBadges = {}, displayOrderBadgeList = [] } = plpBadge
  const router = useRouter()
  const { isAuth, access_token: accessToken } = useSelector(selectAuthState)
  const { toaster } = useSelector(selectGenericState)
  const [general, setGeneral] = useState({
    swatchUrl: "https://kohler.scene7.com/is/image/PAWEB/",
    siteName: "Kohler",
  })
  const [tileUrl, setTileUrl] = useState("")
  const [locale, setLocale] = useState("en-US")
  const [badgesList, setBadgesList] = useState([])
  const [width] = useWindowResize()
  const [toasterContent, setToasterContent] = useState({
    successMessage: "",
    unfavoriteSuccessMessage: "",
  })
  const { comparedProducts, isEnable } = compareData
  const [isChecked, setIsChecked] = useState(false)
  const [showShare, setShowShare] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [onHover, setOnHover] = useState(false)
  const [hoverColor, setHoverColor] = useState({})
  const [selectedColor, setSelectedColor] = useState({})
  const EMPTY_PIXEL =
    "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
  const [sku, setSku] = useState("")
  const [masterCustomerFacingSku, setMasterCustomerFacingSku] = useState("")
  const [productCategory, setProductCategory] = useState("")
  const [colorDetail, setColorDetail] = useState([])
  const [colorDetailWhenPriceIsNotAvail, setColorDetailWhenPriceIsNotAvail] =
    useState([])
  const [colorCount, setColorCount] = useState("")
  const [persona, setPersona] = useState("")
  const [images, setImages] = useState([])
  const dispatch = useDispatch()
  const [selColor, setSelColor] = useState("")
  const [variantPrice, setVariantPrice] = useState(0)
  const [variantDiscPrice, setVariantDiscPrice] = useState(0)
  const [colors, setColors] = useState([{}])
  const [selSku, setSelSku] = useState("")
  const [selCustomerFacingSKU, setSelCustomerFacingSKU] = useState("")
  const [tileTitle, setTileTitle] = useState("")
  const [productName, setProductName] = useState("")
  const [description, setDescription] = useState("")
  const [category, setCategory] = useState("")
  const [shipmentDate, setShipmentDate] = useState("")
  const [badgeVal, setBadgeVal] = useState("")
  const [discountedPrice, setDiscountedPrice] = useState(0)
  const [finalPrice, setFinalPrice] = useState(0)
  const [price, setPrice] = useState(0)
  const [isFavorite, setIsFavorite] = useState(false)
  const [isDiscountedPrice, setIsDiscountedPrice] = useState(0)
  const [detaultSelectedColor, setDetaultSelectedColor] = useState("")
  const [defaultCustomerFacingSku, setDefaultCustomerFacingSku] = useState("")
  const [defaultImageTmp, setDefaultImageTmp] = useState("")
  const [defaultProductIsInStock, setDefaultProductIsInStock] = useState(false)
  const [discountPercent, setDiscountPercent] = useState(0)
  const [discountPriceState, setDiscountPriceState] = useState(0)
  const [productIsRetail, setProductIsRetail] = useState(false)
  const [isSuperSku, setIsSuperSku] = useState(false)
  const [discountAmount, setDiscountAmount] = useState(0)
  const [productSaleable, setProductSaleable] = useState(true)
  const [productRoom, setProductRoom] = useState("")
  const [productCollectionsName, setProductCollectionsName] = useState("")
  const [maxPrice, setMaxPrice] = useState(0)
  const [discountedMaxPrice, setDiscountedMaxPrice] = useState(0)
  const triggerAnalytics = useRef(null)
  const [loginFavouriteCallback, setLoginFavouriteCallback] = useState(null)
  const [isMobile, setIsMobile] = useState(width <= 1203)

  const { primaryBundleSKU_s: primaryBundleSKUs = "" } = data

  useEffect(() => {
    setIsMobile(width <= 1023)
  }, [width])

  const handleFavorite = e => {
    e.preventDefault()
    e.stopPropagation()
    window.preActiveElement = e.target
    if (isFavorite === true) {
      favorites?.map(fav => {
        if (fav.productId === data["ctId_s"]) {
          if (!isAuth) {
            handleLogin(e)
          } else {
            dispatch(removeItems({ ids: [fav.id], isDataLayerNeeded: false }))
              .unwrap()
              .then(() => {
                handleCallBack("success", texts.removeItem)
                dispatch(
                  showToast({
                    message:
                      toasterContent.unfavoriteSuccessMessage ||
                      texts.removeItem,
                    isVisible: true,
                  })
                )
              })
              .catch(err => {
                dispatch(
                  showToast({
                    message: texts.removeItemError,
                    isVisible: true,
                  })
                )
                handleCallBack("failure", texts.removeItemError)
              })
          }
        }
      })
    } else {
      if (!isAuth) {
        handleLogin(e)
      } else {
        dispatch(addProduct(selectedColor?.sku ? selectedColor?.sku : sku))
          .unwrap()
          .then(() => {
            handleCallBack("success", texts.favSuccessMsg)
            dispatch(
              showToast({
                message: toasterContent.successMessage || texts.favSuccessMsg,
                isVisible: true,
              })
            )
          })
          .catch(err => {
            dispatch(
              showToast({
                message: texts.favErrorMsg,
                isVisible: true,
              })
            )
            handleCallBack("failure", texts.favErrorMsg)
          })
      }
    }
    window.loginFavouriteCallback = null
    setLoginFavouriteCallback(null)
  }

  const handleCallBack = (status, message) => {
    const { adobeDataLayer: { getState } = {} } = window
    const page = (getState && getState("page")) || {}
    let isForSample = false
    if (page && page?.category?.subCategory1) {
      const {
        category: { subCategory1 },
      } = page
      isForSample = subCategory1.includes("samples")
    }
    const eventInfo = getEventInfo("favorite", null, true, isForSample)
    const productInfo = getProductInfo(selectedColor, true)
    eventInfo.eventStatus = status
    eventInfo.eventMsg = sanitizeTextForAnalytics(message)
    window.adobeDataLayer.push({
      event: "cmp:click",
      page,
      eventInfo,
      productInfo,
    })
  }

  const handleClick = () => {
    const brandName = sterlingData[0].Brand_ss[0]
    const skuName = sterlingData[0].sku_s
    handleViewSterlingResults(brandSwitcherItems, brandName, skuName)
  }

  useEffect(() => {
    getConfig().then(config => {
      setGeneral(config?.general)
      setLocale(_get(config, "internationalization.locale", ""))
    })
    setToasterMessage()
  }, [])

  useEffect(() => {
    if (!_isEmpty(accessToken)) {
      setPersona(getUserPersona())
    }
  }, [accessToken])

  useEffect(() => {
    if (loginFavouriteCallback && isAuth) {
      handleFavorite(loginFavouriteCallback)
    }
  }, [loginFavouriteCallback, isAuth])

  useEffect(() => {
    if (selectedColor && triggerAnalytics.current) {
      colorClickHandler(selectedColor)
    }
  }, [selectedColor])

  const setToasterMessage = async () => {
    if (!_isEmpty(toaster?.content)) {
      const { successMessage, unfavoriteSuccessMessage } = toaster.content
      setToasterContent({
        successMessage: await getShortenedUrl(successMessage),
        unfavoriteSuccessMessage: await getShortenedUrl(
          unfavoriteSuccessMessage
        ),
      })
    }
  }
  useEffect(() => {
    let tempSku = data["masterSKU_s"]
    if (data["sku_s"]) {
      tempSku = data["sku_s"]
    }
    setSku(tempSku)
    setMasterCustomerFacingSku(data["masterCustomerFacingSKU_s"] ?? "")
    setProductCategory(_get(data, "Product_Category", ""))
  }, [data])
  const isBundleProduct = Boolean(data?.primaryBundleSKU_s)
  const colorDetailPriceIsNotAvail = data[`productImages.url_ss`]?.map(
    (item, index) => `${data[`variantList.sku_ss`][index]}|${item}`
  )
  useEffect(() => {
    if (!_isEmpty(persona)) {
      setColorDetail(data[`Color.${persona}.Details_ss`] ?? [])
      setColorDetailWhenPriceIsNotAvail(colorDetailPriceIsNotAvail)
    }
  }, [data, persona])

  useEffect(() => {
    if (!sku) return
    let imagestmp = []
    const productVariantImages = data["productImages.labelWithUrl_s"]
      ? [data["productImages.labelWithUrl_s"]]
      : data["productImages.labelWithUrl_ss"]
    let setDefaultProductImage = ""
    let setHoverProductImage = ""
    let isHover = true

    if (productVariantImages?.length > 0) {
      const variantImages = productVariantImages.filter(
        img => sku === img.split("|")[0]
      )
      if (plpPage) {
        variantImages.find(img => {
          const imgDetails = img.split("|")
          if (imgDetails[2] === defaultImage)
            return (setDefaultProductImage = imgDetails[1])
          else setDefaultProductImage = variantImages[0].split("|")[1]
        })
        productVariantImages.find(img => {
          const imgDetails = img.split("|")
          if (imgDetails[2] === hoverImage)
            return (setHoverProductImage = imgDetails[1])
        })
      } else {
        productVariantImages.find(img => {
          const imgDetails = img.split("|")
          if (sku === imgDetails[0])
            return (setDefaultProductImage = imgDetails[1])
        })
      }

      imagestmp.push(setDefaultProductImage)
      if (plpPage) {
        if (setHoverProductImage === "") {
          isHover = false
        }
        isHover === false
          ? imagestmp.push(setDefaultProductImage)
          : imagestmp.push(setHoverProductImage)
      }
    } else {
      imagestmp = ["MissingImage"]
    }

    if (mapImageToColor && currFilters) {
      const colorFilter = currFilters.find(q => isColorFacet(q.facet))
      const selColors = colorFilter?.value
      if (selColors?.length > 0) {
        let selectedProductImage = ""
        let i = selColors?.length - 1 ?? 0
        let variantPriceTmp = 0
        let variantDiscPriceTmp = 0
        while (!selectedProductImage && i >= 0) {
          const colorName = selColors[i]
          if (colorDetail?.length) {
            const exactSKUDetails =
              colorDetail
                .find(item => item.split("|")[1] === colorName)
                ?.split("|") ?? []
            setSelColor(selColors[i])
            const productId =
              exactSKUDetails[5]?.split("?")[0].split("/").slice(-1) ?? []
            selectedProductImage = productId[0] ?? ""
            variantPriceTmp = exactSKUDetails[2] ?? 0
            variantDiscPriceTmp = exactSKUDetails[3] ?? 0
          }
          i--
        }
        setVariantPrice(variantPriceTmp)
        setVariantDiscPrice(variantDiscPriceTmp)

        if (!selectedProductImage) selectedProductImage = "MissingImage"
        if (imagestmp[0] === imagestmp[1]) {
          imagestmp = [selectedProductImage, selectedProductImage]
        } else {
          imagestmp[0] = selectedProductImage
        }
      }
    }
    setImages(imagestmp)
    if (selectedColor?.sku) {
      setSelected({ sku: selectedColor?.sku ? selectedColor?.sku : sku })
    }
    let colorsTemp = []
    if (data["Color.SKU.Details_ss"]) {
      colorsTemp = data["Color.SKU.Details_ss"].map(color => {
        const d = color.split("|")
        return {
          name: d[0],
          swatch: d[1],
          sku: d[2],
          isStock: d[3],
          isDiscontinued: d[4],
          customerFacingSKU: d[5],
        }
      })
    } else if (data["Color.SKU.Details_s"]) {
      const d = data["Color.SKU.Details_s"].split("|")
      colorsTemp = [
        {
          name: d[0],
          swatch: d[1],
          sku: d[2],
          isStock: d[3],
          isDiscontinued: d[4],
          customerFacingSKU: d[5],
        },
      ]
    } else if (data["SKU.Details_ss"]) {
      colorsTemp = data["SKU.Details_ss"].map(color => {
        const d = color.split("|")
        return {
          name: "",
          swatch: SWATCH_NA,
          sku: d[0],
          isStock: d[1],
          isDiscontinued: d[2],
          customerFacingSKU: d[3],
        }
      })
    }

    const colorsTempVal = colorsTemp.filter(
      item =>
        item.swatch?.toLowerCase() !== SWATCH_NA.toLowerCase() &&
        (item.isDiscontinued === "false" ||
          Date.parse(item.isDiscontinued) > Date.now())
    )

    setColors(colorsTempVal)

    const {
      Tile_Title: tileTitle = "",
      Collapse_Title_s: collapseTitles = "",
      productName_s: productNames = "",
      RegionBrandName_s: regionBrandNames = "",
    } = data

    setTileTitle(tileTitle)

    setProductName(
      isGrouped
        ? collapseTitles
        : type?.toLowerCase() === "parts"
        ? productNames
        : regionBrandNames || productNames
    )
    if (isSterling && sterlingData.length > 0) {
      setProductName(sterlingData[0].ProductBrandName_s)
    }
    setDescription(
      isGrouped
        ? _get(data, "Collapse_Description_s", "")
        : _get(data, "ProductDescriptionProductShort_s", "")
    )
    setCategory(
      data.Product_Category
        ? data.Product_Category
        : data.ProductLocalCategory_s ?? ""
    )
    setShipmentDate(_get(data, "RegionReleaseforShipment_dt", ""))

    setBadgeVal(_get(data, `priceList.${persona}.saleOffer_s`, ""))
    setDiscountedPrice(_get(data, `priceList.${persona}.finalPrice_d`, 0))
    setFinalPrice(_get(data, `priceList.${persona}.price_d`, 0))
    if (isSterling) {
      setFinalPrice(sterlingData[0][`priceList.${persona}.finalPrice_d`])
    }
    setPrice(_get(data, `priceStartingAt.${persona}_d`, 0))
    setIsFavorite(
      favorites?.find(vl => vl.productId === data["ctId_s"]) ? true : false
    )
    setIsDiscountedPrice(
      _get(data, `priceList.${persona}.discountedPrice_d`, 0)
    )

    setDiscountPercent(
      badgeVal && isDiscountedPrice
        ? 100 - Math.round((isDiscountedPrice / price) * 100)
        : 0
    )
    setDiscountPriceState(
      badgeVal && isDiscountedPrice
        ? "percentOff|" + discountPercent
        : "regular price"
    )
    setProductIsRetail(
      _get(data, "ProductIsRetail", "") === "true" ? true : false
    )
    setIsSuperSku(_get(data, "comboSKUAvailable_b", null) ? true : false)
    setDiscountAmount(
      discountPercent ? Number((price - isDiscountedPrice).toFixed(2)) : 0
    )

    setProductRoom(_get(data, "category1_s", "n/a"))
    setProductCollectionsName(_get(data, "ProductBrandNameDisplay_s", "n/a"))
  }, [
    sku,
    data,
    mapImageToColor,
    currFilters,
    isGrouped,
    favorites,
    hoverColor,
    colorDetail,
  ])

  // Update price on hover
  useEffect(() => {
    if (!sku) {
      return
    }
    if (isGrouped) {
      setFinalPrice(_get(data, `min_price`, 0))
      setMaxPrice(_get(data, `max_price`, 0))
      setDiscountedPrice(_get(data, `disc_min_price`, 0))
      setDiscountedMaxPrice(_get(data, `disc_max_price`, 0))
    } else if (
      mapImageToColor &&
      (selColor || hoverColor) &&
      variantPrice > 0
    ) {
      setFinalPrice(variantPrice)
      setDiscountedPrice(variantDiscPrice)
    } else if (exactSKU) {
      const exactSKUDetails =
        colorDetail.find(item => item.split("|")[0] === sku).split("|") ?? []
      setFinalPrice(exactSKUDetails[2] ?? 0)
      setDiscountedPrice(exactSKUDetails[3] ?? 0)
    }
  }, [
    data,
    hoverColor,
    selColor,
    variantPrice,
    exactSKU,
    sku,
    mapImageToColor,
    isGrouped,
    colorDetail,
    variantDiscPrice,
  ])
  useEffect(() => {
    if (isMobile) {
      setBadgesList(displayOrderBadgeList.slice(0, 2))
    } else {
      setBadgesList(displayOrderBadgeList)
    }
  }, [isMobile])
  useEffect(() => {
    if (!_isEmpty(general)) {
      getPDPUrlForProductTile(data).then(ret => setTileUrl(ret))
    }
  }, [general, data])

  useEffect(() => {
    setDefaultImageTmp(images.length ? images[0] : "")
  }, [images])

  useEffect(() => {
    if (mapImageToColor && selColor && selColor !== "Not Applicable") {
      const selColorDetails = colors.find(item => item.name === selColor) ?? {}
      setSelSku(selColorDetails?.sku)
      setSelCustomerFacingSKU(selColorDetails?.customerFacingSKU)
    } else {
      const currentSku =
        selectedColor && selectedColor.sku ? selectedColor.sku : sku
      setSelSku(currentSku)
      setSelCustomerFacingSKU(masterCustomerFacingSku)
    }
    setDetaultSelectedColor(colors.length ? colors[0]?.name : "")
    setDefaultCustomerFacingSku(
      colors.length ? colors[0]?.customerFacingSKU : ""
    )

    setDefaultProductIsInStock(
      colors.length
        ? colors[0].isStock === "true" || colors[0].isStock === true
        : false
    )
    setProductSaleable(productIsRetail || !colors.length ? false : true)
  }, [
    mapImageToColor,
    masterCustomerFacingSku,
    selColor,
    sku,
    colors,
    productIsRetail,
  ])

  useEffect(() => {
    setColorCount(swatchCount ?? 7)
  }, [swatchCount])
  const [pageRedirectUrl, setPageRedirectUrl] = useState("")
  useEffect(() => {
    const pageredirectUrlVal =
      type?.toLowerCase() === "parts"
        ? `${tileUrl}${selSku ? "?skuId=" + selSku : "?skuId=" + sku}`
        : `${tileUrl}${selSku ? "?skuId=" + selSku : ""}`
    setPageRedirectUrl(pageredirectUrlVal)
  }, [data, type, tileUrl, selSku])

  const cartData = useSelector(state => state.cart.data)
  // Update image on Hover
  const setSelected = color => {
    const skuDetails =
      colorDetail.find(item => item.split("|")[0] === color.sku)?.split("|") ??
      []
    const productId = skuDetails[5]?.split("?")[0].split("/").slice(-1) ?? []
    const skuDetailsWhenPriceIsNotAvail =
      colorDetailWhenPriceIsNotAvail
        .find(item => item.split("|")[0] === hoverColor.sku)
        ?.split("|") ?? []
    const productIdWhenPriceIsNotAvail =
      skuDetailsWhenPriceIsNotAvail[1]?.split("?")[0].split("/").slice(-1) ?? []
    const selProdImage =
      productId[0] ?? productIdWhenPriceIsNotAvail[0] ?? "MissingImage"
    let imgValSelected = images
    if (imgValSelected[0] === imgValSelected[1]) {
      imgValSelected = [selProdImage, selProdImage]
    } else {
      imgValSelected[0] = selProdImage
    }
    setImages(imgValSelected)
    if (!isGrouped) {
      setVariantPrice(skuDetails[2] ?? 0)
      setVariantDiscPrice(skuDetails[3] ?? 0)
    }
  }
  useEffect(() => {
    if (hoverColor) {
      setSelected(hoverColor)
    } else if (selectedColor) {
      setSelected(selectedColor)
    }
  }, [hoverColor])

  useEffect(() => {
    const compareSku = selSku !== "" ? selSku : sku
    if (comparedProducts.find(prod => prod.compareSku === compareSku)) {
      setIsChecked(true)
    } else {
      setIsChecked(false)
    }
  }, [comparedProducts, data, selSku, sku])

  useEffect(() => {
    setHoverColor("")
    setSelectedColor("")
  }, [currFilters, selectedSort])

  const colorSwatchDefaultImg = e => {
    e.target.src = swatchMissing.src
  }

  const handleCompare = (event, sku) => {
    setIsChecked(!isChecked)
    if (event.target.checked) {
      let variantFlag = false
      if (comparedProducts.length < 4) {
        const allVariantSkus = compareData.comparedProducts
          ?.map(a => [...a.variants, a.masterVariant])
          .flat()
          ?.map(a => a.sku)
        if (allVariantSkus?.includes(sku)) {
          variantFlag = true
        }
        if (
          compareData.category !== "" &&
          compareData.category !== productCategory
        ) {
          setErrorMessage(texts.sameCategory)
          setTimeout(() => {
            setErrorMessage("")
          }, 2000)
          setIsChecked(false)
          removeFromCompare(sku)
        } else {
          addToCompare(sku, data?.RegionOnBackOrder_s ?? false)
        }
      } else if (
        variantFlag &&
        process.env.NEXT_PUBLIC_COMPARE_PRODUCTS_VERSION ===
          "compare_products_v2"
      ) {
        setErrorMessage(texts?.sameVariants)
        setTimeout(() => {
          setErrorMessage("")
        }, 2000)
        setIsChecked(false)
        removeFromCompare(sku)
      } else {
        setErrorMessage(texts.maxProduct)
        setTimeout(() => {
          setErrorMessage("")
        }, 2000)
        setIsChecked(false)
        removeFromCompare(sku)
      }
    } else {
      removeFromCompare(sku)
    }
    handleAnalytics("compare")
  }

  const handleAnalytics = type => {
    try {
      const { adobeDataLayer: { getState } = {} } = window
      const page = (getState && getState("page")) || {}
      const eventInfo = getEventInfo(type, null, true)
      const productInfo = getProductInfo(selectedColor, true)
      window.adobeDataLayer.push({
        event: "cmp:click",
        page,
        eventInfo,
        productInfo,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleLogin = e => {
    e.preventDefault()
    e.stopPropagation()
    window.loginFavouriteCallback = () => {
      setLoginFavouriteCallback(e)
    }
    dispatch(setAuthModalVisibility({ show: true }))
  }

  const handleShare = e => {
    e.stopPropagation()
    e.preventDefault()
    setShowShare(true)
    handleAnalytics("share")
  }

  const redirectPDP = e => {
    e.preventDefault()
    e.stopPropagation()
    let redirectUrl

    router.push(decodeURIComponent(pageRedirectUrl))
    window.history.replaceState(
      window.location.pathname,
      document.title,
      window.location.href.includes("?")
        ? `${window.location.search}&back=${masterCustomerFacingSku}`
        : `?back=${masterCustomerFacingSku}`
    )

    if (isSterling) {
      if (parent.toLowerCase() === "utility widgets") {
        redirectUrl = sanitizeUrl(
          window.location.origin +
            searchPagePath +
            `?keyword=${sterlingData[0].sku_s}`
        )
        window.location.href = redirectUrl
      }
    }

    if (isSearchPanelTile) {
      onToggle()
    }
  }

  const handleMouseLeave = () => {
    onHover && setOnHover(false)
    setShowShare(false)
  }

  const handlehoverImgFunction = () => {
    try {
      const hoverImgLists = document.querySelectorAll(
        `#kf-product-list-tile-wrapper-${data["ctId_s"]} img.product-list-tile__hoverimg`
      )
      if (hoverImgLists.length > 0) {
        const hoverImg = hoverImgLists[0]
        const hoverSrc = hoverImg.getAttribute("imgsrc")
        const hoverSrcAlt = hoverImg.getAttribute("imgalt")
        hoverImg.src = hoverSrc
        hoverImg.alt = hoverSrcAlt
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleMouseEnter = () => {
    handlehoverImgFunction() && setOnHover(true)
  }
  const handleTouchStart = () => {
    handlehoverImgFunction() && setOnHover(true)
  }
  const handleTouchEnd = () => {
    onHover && setOnHover(false)
    setShowShare(false)
  }
  const presetConf = JSON.parse(presetConfigs)

  const getEventInfo = (
    type,
    color,
    needsJSON = false,
    isForSample = false,
    sterling = false
  ) => {
    /**
     * type is default navigation, could be favorite or share
     * color is optional, could be selected color from plp or search page product
     * needsJSON returns either JSON or stringified value
     */
    let alternameType
    let compareInternalLinkName
    if (type === "favorite") {
      if (isFavorite) {
        alternameType = "favorite remove"
      } else {
        alternameType = "favorite add"
      }
    } else if (type === "compare") {
      if (isChecked) {
        alternameType = "compare remove"
        compareInternalLinkName = "remove compare"
      } else {
        alternameType = "compare add"
        compareInternalLinkName = "add to compare"
      }
    }
    let selectedTab
    let pageSection
    if (favPage && selectedtab) {
      pageSection = "favorites"
      selectedTab = selectedtab.toLowerCase()
    }

    const plpPrefix = isForSample ? "plp:sample" : "plp"

    const selectedTabForSearchResults = type || "products"

    const colorSelection = color
      ? "color selection"
      : alternameType ||
        sanitizeTextForAnalytics(type || productName + " " + description)
    const eventInfo = {
      eventAction: `${
        pageSection
          ? "my account:" + pageSection + ":"
          : plpPage
          ? plpPrefix
          : "search results"
      } product ${alternameType || type || "navigation"}`,
      eventName: `${
        pageSection
          ? "my account:" + pageSection + ":"
          : plpPage
          ? plpPrefix
          : "search results"
      } product ${alternameType || type || "navigation"}`,
      eventType:
        isForSample || type === "favorite" ? "wishlist save" : "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: sterling
        ? "view sterling results"
        : type === "compare"
        ? compareInternalLinkName
        : colorSelection,
      internalLinkPosition:
        selectedTab ?? (plpPage ? plpPrefix : "search results"),
      internalLinkType: pageSection
        ? "my accounts:navigation"
        : `${plpPage ? plpPrefix : "search results"}:${
            type || (plpPage ? "navigation" : "product tiles")
          }`,
      internalLinkZoneName: selectedTab
        ? `my account:${pageSection}:${selectedTab}:main content area`
        : plpPage
        ? `${plpPrefix}:promo tiles`
        : `search results:${selectedTabForSearchResults}:main content area`,
      internalLinkURL: isSterling
        ? window.location.href
        : !type
        ? (tileUrl?.indexOf("http") === -1 ? window.location.origin : "") +
          tileUrl +
          (color ? "?skuId=" + color.sku : selSku ? "?skuId=" + selSku : "")
        : "n/a",
      clickInternalLinks: "true",
    }

    if (needsJSON) {
      return eventInfo
    }
    return JSON.stringify(eventInfo)
  }

  const getProductInfo = (color, needsJSON = false, sterling = false) => {
    const { productCouponCode, productCoupondiscount } =
      getCurrentSkuCouponDiscounts({ cart: cartData, sku: sku, locale: locale })

    const colorDetail = data[`Color.SKU.Details_ss`] ?? []
    const colorName = color
      ? colorDetail
          .find(item => item.split("|")[2] === color.sku)
          ?.split("|")[0] ?? ""
      : ""
    const inStock = color
      ? colorDetail
          .find(item => item.split("|")[2] === color.sku)
          ?.split("|")[3] ?? ""
      : ""
    const description = isGrouped
      ? _get(data, "Collapse_Description_s", "")
      : _get(data, "ProductDescriptionProductShort_s", "")
    let productId =
      (color ? color.customerFacingSKU : defaultCustomerFacingSku) ||
      (data["masterCustomerFacingSKU_s"] ?? "n/a")
    if (isSterling && sterlingData.length) {
      productId = sterlingData[0]?.sku_s ?? ""
    }
    const productInfo = {
      productInfo: {
        productBrand: sterling ? "sterling" : general?.siteName?.toLowerCase(),
        description: sanitizeTextForAnalytics(description) || "n/a",
        globalPromotionPrice:
          discountPercent && discountAmount
            ? Number((price - discountAmount).toFixed(2))
            : price,
        priceState: discountPriceState,
        productBasePrice: price,
        productCategory: sanitizeTextForAnalytics(category) || "n/a",
        productColor:
          sanitizeTextForAnalytics(
            colorName ? colorName : detaultSelectedColor
          ) || "n/a",
        productID: productId,
        productName: sanitizeTextForAnalytics(productName) || "n/a",
        productRoom: sanitizeTextForAnalytics(productRoom || category) || "n/a",
        productSalePrice: discountedPrice ? discountedPrice : price,
        productSaleable: productSaleable,
        productStatus: color
          ? inStock === "true" || inStock === true
            ? "in stock"
            : "out of stock"
          : defaultProductIsInStock
          ? "in stock"
          : "out of stock",
        productSuperSku: isSuperSku,
        productTileName: sanitizeTextForAnalytics(tileTitle) || "n/a",
        defaultImageName: defaultImageTmp || "n/a",
        productCollectionsName:
          sanitizeTextForAnalytics(productCollectionsName || category) || "n/a",
        globalOffer: badgeVal ? badgeVal : "n/a",
        globalDiscount: discountPercent ? discountAmount : "n/a",
        productPartnerBuyNow: productIsRetail,
        pdpType: isBundleProduct
          ? "bundle"
          : "regular finished goods & service parts",
        productFindingMethod: "n/a",
        productCouponCode: productCouponCode.join("|") || "n/a",
        productCoupondiscount: productCoupondiscount,
        frequency: "n/a",
        isRecommended: "n/a",
        isSendNow: "n/a",
        isSubscription: "n/a",
        quantity: "n/a",
        ratings: "n/a",
        numberOfReviews: "n/a",
      },
    }
    if (needsJSON) {
      return productInfo.productInfo
    }
    return JSON.stringify(productInfo)
  }

  const onSelectedColor = (e, color) => {
    e.stopPropagation()
    const { sku, customerFacingSKU } = color
    setSelectedColor({ sku: sku, customerFacingSKU: customerFacingSKU })
    setSelected({ sku: sku, customerFacingSKU: customerFacingSKU })
    triggerAnalytics.current = true
  }

  const colorClickHandler = color => {
    triggerAnalytics.current = false
    const { adobeDataLayer: { getState } = {} } = window
    const page = (getState && getState("page")) || {}
    const eventInfo = getEventInfo(null, color, true, false, false)
    const productInfo = getProductInfo(color, true)
    window.adobeDataLayer.push({
      event: "cmp:click",
      page,
      eventInfo,
      productInfo,
    })
  }

  const checkColorFocus = (e, colors, index) => {
    const colorSlice = colors.slice(0, colorCount)
    if (
      (e.key === "Tab" && colorSlice.length - 1 === index) ||
      (e.shiftKey && e.key === "Tab" && index === 0)
    ) {
      setHoverColor(selectedColor ?? "")
    }
  }
  const handleOnClickPartsDiagramLinkTmp = event => {
    event.preventDefault()
    event.stopPropagation()
    handleOnClickPartsDiagramLink(data)
    partsAnalytics()
  }
  const partsAnalytics = () => {
    try {
      const { adobeDataLayer: { getState } = {} } = window
      const page = (getState && getState("page")) || {}
      const eventInfo = getEventInfo(
        partsDiagram.toLowerCase(),
        null,
        true,
        true
      )
      const productInfo = getProductInfo(selectedColor, true)
      window.adobeDataLayer.push({
        event: "cmp:click",
        page,
        eventInfo,
        productInfo,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const partsDiagram = t("kf.search.partsdiagram")
  const serviceParts = t("kf.search.serviceparts")

  const productTileClickHandler = async () => {
    const { adobeDataLayer: { getState } = {} } = window
    const page = (getState && getState("page")) || {}
    const eventInfo = getEventInfo("", null, true)
    const productInfo = getProductInfo(null, true)
    window.adobeDataLayer.push({
      event: "cmp:click",
      page,
      eventInfo,
      productInfo,
    })
  }

  const viewSterlingResultsHandler = async () => {
    const { adobeDataLayer: { getState } = {} } = window
    const page = (getState && getState("page")) || {}
    const eventInfo = getEventInfo("", null, true, true, true)
    const productInfo = getProductInfo(null, true, true)
    window.adobeDataLayer.push({
      event: "cmp:click",
      page,
      eventInfo,
      productInfo,
    })
  }

  const getDivPropd = () =>
    isSterling ? {} : { onClick: debounce(productTileClickHandler, 500) }

  const renderBadge = item => {
    return <p className="product-list-tile__new">{plpBadges[item]?.value}</p>
  }
  const isThreeDBadgeAvailable = data => {
    return (
      data["ResourceNvznId_s"] ||
      data["ResourceVntanaProductId_s"] ||
      data["ResourceVntanaProductIdList_ss"]?.length ||
      data["ResourceNvznIdList_ss"]?.length
    )
  }

  const tileDetails = (
    <>
      <div className="product-list-tile__image wow">
        <div>
          {images.map((id, i) => {
            const imgUrl = getPresetUrl(
              width,
              rowCols,
              general.swatchUrl,
              id,
              presetConf
            )

            return (
              <img
                key={id + "_IMG_" + i}
                id={id + "_IMG_" + i}
                role="presentation"
                src={
                  isSterling
                    ? data["productImage.url_ss"][0]
                    : i === 0
                    ? imgUrl
                    : images[0] != images[1]
                    ? EMPTY_PIXEL
                    : imgUrl
                }
                className={cx(
                  {
                    "product-list-tile__image--hover test1":
                      images.length > 1 && i === 0,
                  },
                  {
                    "product-list-tile__hoverimg test2":
                      images.length > 1 && i !== 0,
                  }
                )}
                alt={data.metaTitle_s}
                imgsrc={imgUrl}
                imgalt={data.metaTitle_s}
                onError={e => onImageError(e, imgUrl)}
              />
            )
          })}
        </div>
      </div>
      {!favPage && (
        <div className="product-list-tile__share">
          <button
            id={`kf-add-to-fav-${index}`}
            className="product-list-tile__share-icon"
            tabIndex="0"
            aria-label={t("kf.plp.label.wishlist", {
              product: productName,
            })}
            aria-hidden={width > 1024 ? (onHover ? false : true) : false}
            onClick={isAuth ? handleFavorite : handleLogin}
            onFocus={() => {
              setOnHover(true)
            }}
            onBlur={() => {
              setOnHover(false)
            }}
          >
            <img
              role="presentation"
              src={isFavorite ? filledWishIcon.src : wishIcon.src}
              alt={texts.wishlist}
            />
          </button>
          <button
            className="product-list-tile__share-icon-plp"
            tabIndex="0"
            aria-label={t("kf.plp.label.share.aria", {
              product: productName,
            })}
            aria-hidden={width > 1024 ? (onHover ? false : true) : false}
            onClick={handleShare}
            onFocus={() => {
              setOnHover(true)
            }}
            onBlur={() => {
              setOnHover(false)
            }}
          >
            <img role="presentation" src={shareIcon.src} alt={texts.share} />
          </button>
        </div>
      )}{" "}
    </>
  )
  if (!(locale && locale !== "") && loading) {
    return (
      <div className="product-list-tile--loading">
        <div className="loading-image shimmer"></div>
        <div className="loading-title shimmer"></div>
        <div className="loading-description shimmer"></div>
        <ul className="loading-swatch">
          <li className="shimmer"></li>
          <li className="shimmer"></li>
          <li className="shimmer"></li>
          <li className="shimmer"></li>
          <li className="shimmer"></li>
        </ul>
      </div>
    )
  }

  const handleColorClick = (e, color) => {
    onSelectedColor(e, color)
    redirectPDP(e)
  }

  return (
    <div
      id={`kf-product-list-tile-wrapper-${data["ctId_s"]}`}
      className={`product-list-tile__wrapper wow  ${onHover && "--hover"}`}
      onFocus={() => {
        setOnHover(true)
      }}
      onBlur={() => {
        setOnHover(false)
      }}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {isSterling && showSterlingButton ? (
        <div className="family-of-brands">{staticText.familyResults}</div>
      ) : null}
      <ProductSocialShare
        showModal={showShare}
        id={`kf-plp-tile-${data["ctId_s"]}`}
        url={window.location.origin + tileUrl}
        onClose={() => setShowShare(false)}
        analyticData={getEventInfo("share", null, true)}
        disableInstagram
      />
      <div
        id={`Inner-${masterCustomerFacingSku}`}
        role="link"
        tabIndex="0"
        className="product-list-tile__wrapper__card"
        aria-label={t("kf.plp.ariaLabel.productInfo", {
          productName: productName,
          description: description,
          price:
            discountedPrice > 0
              ? `Sale Price: ${
                  currencySign + " " + discountedPrice
                } Original Price: ${currencySign + " " + price}`
              : `Price: ${currencySign + " " + price}`,
        })}
        onFocus={e => e.stopPropagation()}
        onClick={
          !(isSterling && parent.toLowerCase() === "search")
            ? redirectPDP
            : () => {}
        }
      >
        <div
          className={cx({
            "product-list-tile": true,
            "product-list-tile--lite": lite,
          })}
        >
          {isSterling && parent.toLowerCase() === "search" ? (
            <div style={{ cursor: "default" }}>{tileDetails}</div>
          ) : (
            <a
              role="link"
              tabIndex="-1"
              href={`${tileUrl}?skuId=${selSku !== "" ? selSku : sku}`}
              onClick={debounce(productTileClickHandler, 500)}
            >
              {tileDetails}
            </a>
          )}
          <div
            className={
              isSterling && parent.toLowerCase() === "search"
                ? "product-list-tile__sterling"
                : "product-list-tile__details wow"
            }
          >
            <div className="product-list-tile__details product-list-tile__compare-main">
              <div {...getDivPropd()}>
                <div
                  aria-label={`${colors.length}${texts.colorsLinkAria}`}
                  className="product-list-tile__colors"
                >
                  {colors.length > 0 && (
                    <div className="product-list-tile__colors--swatch">
                      <fieldset
                        role="radiogroup"
                        aria-labelledby="color-options-label"
                      >
                        <legend id="color-options-label" className="legend">
                          Color Options
                        </legend>
                        {colors.slice(0, colorCount).map((color, i) => (
                          <label
                            htmlFor={`${color.name}-id`}
                            key={`color-${color.sku}-${color.customerFacingSKU}-${i}`}
                            onClick={e => {
                              handleColorClick(e, color)
                            }}
                            role="radio"
                            tabIndex="0"
                            aria-label={`${color.name} color`}
                            aria-checked={selectedColor.sku === color.sku}
                            aria-hidden={onHover ? false : true}
                            rel="noreferrer"
                            title={color.name}
                            className={cx({
                              "product-list-tile__color-swatch": true,
                              "selected-swatch":
                                selectedColor.sku === color.sku,
                            })}
                            onMouseEnter={() => {
                              setHoverColor({
                                sku: color.sku,
                                customerFacingSKU: color.customerFacingSKU,
                              })
                            }}
                            onMouseLeave={() =>
                              setHoverColor(selectedColor ?? {})
                            }
                            onFocus={() => {
                              setHoverColor({
                                sku: color.sku,
                                customerFacingSKU: color.customerFacingSKU,
                              })
                            }}
                            onKeyDown={e => checkColorFocus(e, colors, i)}
                          >
                            <input
                              type="radio"
                              id={`${color.name}-id`}
                              aria-label={`${color.name}`}
                              aria-checked={selectedColor.sku === color.sku}
                            />
                            <img
                              role="presentation"
                              src={`${general.swatchUrl}${color.swatch}${
                                width <= 768
                                  ? "?$PLPSwatch_Mobile50$"
                                  : "?$PLPSwatch_Desk100$"
                              }`}
                              alt={color.name}
                              onError={e => colorSwatchDefaultImg(e)}
                            />
                          </label>
                        ))}
                      </fieldset>
                      <span>
                        {colors.length > colorCount &&
                          "+" + (colors.length - colorCount)}
                      </span>
                    </div>
                  )}
                </div>
                <div
                  className={cx({
                    "product-list-tile__info": true,
                    "mt-0": !colors.length,
                  })}
                >
                  {!general?.disableCommerce && (
                    <div className="product-list-tile__badge">
                      {badgesList?.map(item => (
                        <React.Fragment key={item}>
                          {item.includes("new") && showNewBadge(shipmentDate)
                            ? renderBadge(item)
                            : null}
                          {item.includes("sale") && !_isEmpty(badgeVal)
                            ? renderBadge(item)
                            : null}
                          {item.includes("3d") && isThreeDBadgeAvailable(data)
                            ? renderBadge(item)
                            : null}
                          {item.includes("exclusive") &&
                          data["ProductIsExclusive_s"]
                            ? renderBadge(item)
                            : null}
                        </React.Fragment>
                      ))}
                    </div>
                  )}

                  {productName && (
                    <>
                      <h3 className="product-list-tile__crawlableData">
                        {data?.productName_s}
                      </h3>
                      <h3
                        aria-label={productName}
                        className="product-list-tile__title"
                      >
                        <Highlight
                          text={productName}
                          highlight={highlightText}
                        />
                      </h3>
                    </>
                  )}
                  <div
                    aria-label={`${texts.productDescription}${description}`}
                    className="product-list-tile__description"
                  >
                    <Highlight text={description} highlight={highlightText} />
                  </div>
                </div>
                <p className="product-list-tile__product-sku">
                  {hoverColor
                    ? hoverColor.sku && hoverColor.customerFacingSKU
                    : selectedColor
                    ? selectedColor.sku && selectedColor.customerFacingSKU
                    : selSku || selCustomerFacingSKU}
                </p>
                <div>
                  {finalPrice > 0 && (
                    <p
                      aria-label={`${texts.salePriceAria}${currencySign}${
                        !general?.disableCommerce && discountedPrice > 0
                          ? discountedPrice
                          : finalPrice ?? 0
                      } 
                                ${
                                  isGrouped &&
                                  (discountedPrice > 0
                                    ? discountedPrice !== discountedMaxPrice
                                    : finalPrice !== maxPrice)
                                    ? " - " +
                                      currencySign +
                                      (discountedMaxPrice > 0
                                        ? discountedMaxPrice
                                        : maxPrice)
                                    : ""
                                }`}
                      className={`product-list-tile__price ${
                        !isGrouped && "product-list-tile_paddingtop"
                      }`}
                    >
                      <NumericFormat
                        value={
                          !general?.disableCommerce && discountedPrice > 0
                            ? parseFloat(discountedPrice)
                            : parseFloat(finalPrice ?? 0)
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={currencySign}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                      {isGrouped &&
                        (!general?.disableCommerce && discountedPrice > 0
                          ? discountedPrice !== discountedMaxPrice
                          : finalPrice !== maxPrice) && (
                          <NumericFormat
                            value={
                              discountedMaxPrice > 0
                                ? parseFloat(discountedMaxPrice)
                                : parseFloat(maxPrice ?? 0)
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={` - ${currencySign}`}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        )}
                      {!general?.disableCommerce &&
                        discountedPrice !== finalPrice &&
                        discountedPrice > 0 && (
                          <span
                            aria-label={`${
                              texts.originalPriceAria
                            }${currencySign}${finalPrice ?? 0}${
                              isGrouped && finalPrice !== maxPrice
                                ? " - " + currencySign + discountedMaxPrice
                                : ""
                            }`}
                            className={cx({
                              "product-list-tile__discount-price": true,
                              "pl-0 d-block":
                                isGrouped && width <= 480 && rowCols === 2,
                            })}
                          >
                            <NumericFormat
                              value={parseFloat(finalPrice ?? 0)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={currencySign}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                            {isGrouped && finalPrice !== maxPrice && (
                              <NumericFormat
                                value={parseFloat(maxPrice)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={` - ${currencySign}`}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            )}
                          </span>
                        )}
                    </p>
                  )}
                </div>
              </div>
              {!isSterling && !primaryBundleSKUs ? (
                <div className="product-list-tile__right-controls">
                  {!hideCompare && (
                    <div
                      onClick={e => e.stopPropagation()}
                      className={`product-list-tile__compare ${
                        isTablet ? "product-list-tile__compare-tablet" : ""
                      }`}
                    >
                      <Checkbox
                        id={`kf-checkbox-${selSku !== "" ? selSku : sku}`}
                        index={index}
                        value={texts.addToCompare}
                        aria={`${description} ${texts.addToCompare}`}
                        checked={isChecked}
                        onChange={e =>
                          handleCompare(e, selSku !== "" ? selSku : sku)
                        }
                        disabled={isEnable}
                      />
                      {errorMessage !== "" && (
                        <Tooltip message={errorMessage} />
                      )}
                    </div>
                  )}
                  {isPartsDiagramLinkVisible &&
                    Boolean(
                      _get(
                        data,
                        "ProductResource.InteractiveSVG.resourceName_ss",
                        ""
                      )
                    ) &&
                    Boolean(_get(data, "ProductProductNo_s", "")) && (
                      <div
                        className={cx({
                          "product-tile__wrapper__card__parts": true,
                          "product-tile__wrapper__card__parts--lite": lite,
                          "product-tile__wrapper__card__parts--no-compare":
                            isGrouped || hideCompare,
                        })}
                      >
                        <a
                          href="#"
                          className="product-parts__viewer"
                          role="link"
                          tabIndex="0"
                          aria-label={serviceParts}
                          rel="noreferrer"
                          title={serviceParts}
                          onClick={e => handleOnClickPartsDiagramLinkTmp(e)}
                        >
                          {serviceParts}
                        </a>
                      </div>
                    )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {isSterling && showSterlingButton && (
        <div
          className="view-sterling-results"
          onClick={debounce(viewSterlingResultsHandler, 500)}
        >
          <span className="sterling-results-button" onClick={handleClick}>
            {staticText.viewSterlingResults}
          </span>
        </div>
      )}
    </div>
  )
}

export default React.memo(ProductTile)
